.icon-button {
  margin-bottom: 10px;
  i,
  img {
    color: white;
    // border-radius: 2.6rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1.3rem;
    height: 2.6rem;
    width: 2.6rem;
    line-height: 2.6rem;
    margin: 0 5px;
    position: relative;
    text-align: center;
    user-select: none;
    margin-bottom: 10px;
    transition: 0.2s ease-in;
  }

  &:hover {
    i,
    img {
      height: 3rem;
      width: 3rem;
    }
  }
}
