#topButton {
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 25px;
}

#topButton:hover {
  // background-color: #000;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}
