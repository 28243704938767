header {
  height: 10vh;

  a {
    font-size: large;
    display: inline-block;
    position: relative;

    @include media-breakpoint-down(md) {
      font-size: medium;
    }
    &:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: $dark;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    &:hover:after {
      width: 100%;
      left: 0;
    }
  }
}

.menu-mobile {
  z-index: 1;
  position: absolute;
  margin-top: 10vh;
  height: 90vh;
  background-color: $light;
  width: 100%;
}
