html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  display: none;
}

p,
ul,
ol {
  font-size: 20px;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    font-size: large;
    font-weight: 300;
  }
  @include media-breakpoint-down(sm) {
    font-size: medium;
  }
}
